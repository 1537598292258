import {
    useLoaderData,
    Link,
} from "react-router-dom";
import { fetchList } from "./apis";
import { authHeaders } from "./store";

export async function loader({ params, request }) {
    const data = await fetchList(authHeaders);
    return { data };
}

export default function Item() {
    const t = useLoaderData();
    let data = t?.data?.detail;
    return (
        <>
            {data.length === 0 ? 
            <Link to="/create"><button>Create a new item</button></Link> :
            <div>
                {data.map((item) => (
                    <Link className="item-link" to={`/item/${item.id}`} key={item.id}>
                        <div className="item">
                            <h3>{item.id}</h3>
                        <pre className="json overflow">{JSON.stringify(item.destinations, null, 2)}</pre>
                        </div>
                    </Link>
                ))}
            </div>}
            
        </>
    );
}
import { authHeaders } from "./store";

class ApiError extends Error {
    constructor(status, statusText, message) {
        if (message === undefined) {
            message = `${status} ${statusText}`;
        }
        super(message);
        this.name = "ApiError";
        this.status = status;
        this.statusText = statusText;
    }
}


// Use ENV variable to set API_URL
export const API_URL = process.env.REACT_APP_API_URL;


export async function fetchList(authHeaders) {
    const response = await fetch(`${API_URL}/list?detail=true`, {
        headers: authHeaders,
        method: "GET",
    });
    if (response.ok) {
        return await response.json();
    }
    throw new ApiError(response.status, response.statusText)
}


export async function fetchItem(id, authHeaders) {
    const response = await fetch(`${API_URL}/${id}/setting`, {
        headers: authHeaders,
        method: "GET",
    });
    if (response.ok) {
        const data = await response.json();
        return {id, data}
    }
    throw new ApiError(response.status, response.statusText)
}


export async function updateItem(id, authHeaders, data) {
    const response = await fetch(`${API_URL}/${id}/setting`, {
        headers: authHeaders,
        method: "POST",
        body: data
    });
    if (response.ok) {
        return await response.json();
    }
    throw new ApiError(response.status, response.statusText)
}


export async function createItem(data) {
    const response = await fetch(`${API_URL}/new`, {
        headers: authHeaders,
        method: "POST",
        body: data
    });
    if (response.ok) {
        return await response.json();
    }
    throw new ApiError(response.status, response.statusText)
}

export async function deleteItem(id, authHeaders) {
    const response = await fetch(`${API_URL}/${id}`, {
        headers: authHeaders,
        method: "DELETE",
    });
    if (response.ok) {
        return true;
    }
    throw new ApiError(response.status, response.statusText)
}

export async function pushMessage(id, title, content, author) {
    const response = await fetch(`${API_URL}/${id}`, {
        method: "POST",
        body: JSON.stringify({
            title: title,
            content: content,
            author: author
        })
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new ApiError(response.status, response.statusText)
    }
}
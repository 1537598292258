import { Outlet, Link } from "react-router-dom";
import { authInfo } from "./store";

export default function App() {
  return (
    <>
      <div id="sidebar">
        {authInfo.username ? <h1>{authInfo.username}</h1> : <h1>Pusher</h1> }
        <nav>
          <ul>
            <li>
              <Link to={`/push`}>Push</Link>
            </li>
            <li>
              <Link to={`/item`}>Items</Link>
            </li>
            <li>
              <Link to={`/create`}>Create</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}
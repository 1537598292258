import { Form } from "react-router-dom";
  

export default function EditItem({defaultValue, disabled=false}) {
  const data = JSON.stringify(defaultValue, null, 2);
  function restoreDefault() {
    document.getElementById("item-form").reset();
  }
  return (
    <Form method="post" id="item-form">
      <label>
        <textarea
          name="data"
          defaultValue={data}
          rows={6}
          className="json"
        />
      </label>
      <label>
        <button type="submit" disabled={disabled}>Save</button>
        <button type="button" onClick={restoreDefault}>Reset</button>
      </label>
    </Form>
  );
}

import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Item, {action as appAction, loader as appLoader} from "./item";
import List, {loader as listLoader} from "./list";
import Push, {action as pushAction} from "./push";
import Create, {action as createAction} from "./create";
import Login from "./login";

/* previous imports */
import ErrorPage from "./error-page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "push",
        element: <Push />,
        action: pushAction
      },
      {
        path: "item",
        element: <List />,
        loader: listLoader
      },
      {
        path: "item/:id",
        element: <Item />,
        action: appAction,
        loader: appLoader
      },
      {
        path: "create",
        element: <Create />,
        action: createAction
      }
    ]
  }, {
    path: "/login",
    element: <Login />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

export let authHeaders = {}
export const authInfo = {
    username: null
}

export function setAuth(headers, username) {
    authHeaders = headers;
    localStorage.setItem('authHeaders', JSON.stringify(headers));
    localStorage.setItem('username', username);
    authInfo.username = username;
}

export function clearAuth() {
    authHeaders = {};
    localStorage.removeItem('authHeaders');
    localStorage.removeItem('username');
    authInfo.username = null;
}

function loadAuth() {
    const authHeaders = localStorage.getItem('authHeaders');
    const username = localStorage.getItem('username');
    if (authHeaders) {
        setAuth(JSON.parse(authHeaders), username);
    }
}

loadAuth();
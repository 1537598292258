import TelegramLoginButton from 'react-telegram-login';
import { setAuth } from "./store";

const handleTelegramResponse = response => {
    const authHeaders = {
      'X-Auth-Client': 'telegram',
      'Authorization': btoa(JSON.stringify(response))
    }
    setAuth(authHeaders, response.username? response.username : response.first_name + ' ' + response.last_name);
    // redirect to query "redirect" or to the home page
    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get("redirect");
    if (redirect != null) {
        window.location.href = redirect;
    }
    window.location.href = "/";
};

export default function Login() {
    return (
        <div id="login">
            <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="TbcpusherBot"/>
        </div>
    )
}
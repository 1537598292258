import {
    redirect,
} from "react-router-dom";
import { createItem } from "./apis";
import EditItem from "./edit";


export async function action({ request, params }) {
    const formData = await request.formData();
    const values = Object.fromEntries(formData);
    const data = values.data;
    const ret = await createItem(data);
    return redirect(`/item/${ret.id}?secret=${ret.secret}&created=true`);
}

export default function Item() {
    const defaultData = {
        "destinations": [
            {
                "hook": "https://example.com",
                "data": null
            }
        ]
    }
    return (
    <>
        <div>
            <h3>Setting</h3>
            <EditItem defaultValue={defaultData} />
        </div>
    </>
    );
}
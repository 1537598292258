import { useState } from 'react';
import {
    Form,
} from "react-router-dom";
import { pushMessage } from "./apis";

const pd = {
    setMessage: null,
    setButtonDisabled: null,
}

export async function action({ request, params }) {
    pd.setMessage('🤔 Pushing...')
    pd.setButtonDisabled(true);
    const formData = await request.formData();
    const values = Object.fromEntries(formData);
    const id = values.id;
    const title = values.title;
    const content = values.content;
    const author = values.author;
    try {
        const r = await pushMessage(id, title, content, author);
        console.log(r)
        if (!r.all_succ) {
            pd.setMessage(`${r.num_failed} of ${r.num_failed + r.num_success} failed`);
        } else {
            pd.setMessage('🥳 Success');
        }
    } catch (e) {
        console.error(e);
        pd.setMessage(`😱 ${e.message}`);
    }
    setTimeout(() => {
        pd.setButtonDisabled(false);
    }, 1000);
    
    return null;
}

export default function Item() {
    const [message, setMessage] = useState("🙂");
    pd.setMessage = setMessage;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    pd.setButtonDisabled = setButtonDisabled;
    return (
        <>
            <div>
                {message && <h1>{message}</h1>}
            </div>
            <div>
                <Form id="search-form" role="search" method="post">
                    <label>
                        <p>ID</p>
                        <input
                            id="q"
                            aria-label="Input ID"
                            placeholder="ID"
                            type="text"
                            name="id"
                        />
                    </label>
                    <label>
                        <p>Title</p>
                        <input
                            aria-label="Title"
                            placeholder="Title"
                            type="text"
                            name="title"
                        />
                    </label>
                    <label>
                        <p>Content</p>
                        <textarea
                            aria-label="Content"
                            placeholder="Content"
                            type="text"
                            name="content"
                        />
                    </label>
                    <label>
                        <p>Author</p>
                        <input
                            aria-label="Author"
                            placeholder="Author"
                            type="text"
                            name="author"
                        />
                    </label>
                    <label>
                        <button disabled={buttonDisabled} type="submit">Submit</button>
                    </label>
                </Form>
            </div>
        </>
    );
}
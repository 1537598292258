import { useRouteError, Link } from "react-router-dom";
import { authInfo } from "./store";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  if (error.status === 401) {
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        {
          authInfo === null ?
          <>
            <p>Sorry, you are not authorized to access this page.</p>
            <p><i>{error.statusText || error.message}</i></p>
          </>
          : <>
              <p>Sorry, please login to access this page.</p>
              <p><Link to={`/login?redirect=${encodeURIComponent(window.location.href)}`}>Login</Link></p>
            </>
        }
      </div>
    );
  }
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      {error.status === 404 ?
        <p>Sorry, we could not find what you were looking for.</p>
        :
        <p>Sorry, an unexpected error has occurred.</p>
      }
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

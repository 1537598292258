import {
    useLoaderData,
    redirect,
    Form,
} from "react-router-dom";
import { useState } from 'react';
import { API_URL, fetchItem, updateItem, deleteItem } from "./apis";
import EditItem from "./edit";
import { authHeaders } from "./store";


const pd = {
    setMessage: null,
    setButtonDisabled: null,
}

export async function loader({ params, request }) {
    return await fetchItem(params.id, authHeaders);
}

export async function action({ request, params }) {
    if (request.method === "DELETE") {
        await deleteItem(params.id, authHeaders);
        return redirect("/item");
    }
    pd.setMessage('🤔 Submitting...')
    pd.setButtonDisabled(true);
    const formData = await request.formData();
    const values = Object.fromEntries(formData);
    const data = values.data;
    await updateItem(params.id, authHeaders, data);
    pd.setMessage('🥳 Success');
    pd.setButtonDisabled(false);
    setTimeout(() => {
        pd.setMessage('');
    }, 2000);
    return null;
}

export default function Item() {
    const [message, setMessage] = useState("");
    pd.setMessage = setMessage;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    pd.setButtonDisabled = setButtonDisabled;
    const t = useLoaderData();
    const data = t.data;
    const id = t.id
    const created = new URL(window.location.href).searchParams.get("created");
    return (
        <>
            <div>
                <h2>{id}</h2>
                {message && <h1>{message}</h1>}
                {
                    created && 
                    <div>
                        <p>Created!</p>
                        <p>Please save the ID:</p>
                        <p style={{color: "red"}}>
                            {id}
                        </p>
                    </div>
                }
                <h3>Push URL</h3>
                <p className="boxed"> {`${API_URL}/${id}`} </p>
                To push data to this item, send a GET or POST request to this URL with "title", "content" and "author" as query parameters, form data or JSON in body. 
            </div>
            <div>
                <h3>Setting</h3>
                <EditItem disabled={buttonDisabled} defaultValue={data} />
            </div>
            <div>
                <h3>Danger Zone</h3>
                <Form method="delete" className="danger">
                    <label>
                        <button type="submit" disabled={buttonDisabled}>Delete</button>
                    </label>
                </Form>
            </div>
        </>
    );
}